import React from "react";
import { connect } from "react-redux";
import ProfileView from "../components/ProfileView";
import withTranslation from "../hocs/withTranslation";
import { getStyles } from "../store/selectors";
import {
  saveProfile,
  resetSaveProfile,
  saveProfilePicture,
  loadUserDetails,
} from "../store/user/actions";

const ProfilePage = (props) => <ProfileView {...props} />;

const mapStateToProps = (state, props) => {
  const {
    user,
    app: { keyboardOpen, granted },
  } = state;

  return { user, keyboardOpen, granted, appStyles: getStyles(state, props) };
};

const mapDisaptchToProps = (dispatch) => {
  return {
    saveProfile: (userDetails) => dispatch(saveProfile(userDetails)),
    saveProfilePicture: (image) => dispatch(saveProfilePicture(image)),
    resetSaveProfile: () => dispatch(resetSaveProfile()),
    loadUserDetails: () => dispatch(loadUserDetails()),
  };
};

export default connect(
  mapStateToProps,
  mapDisaptchToProps,
)(withTranslation(ProfilePage));
